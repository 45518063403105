/* eslint-disable */

function getCookie(cname) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(';');
  for(var i = 0; i <ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

if (getCookie('wpl_viewed_cookie') == 'yes') {
  if (JSON.parse(getCookie('wpl_user_preference')).analytics == "yes") {
    enableGoogleAnalytics();
  }
}

window.addEventListener('GdprCookieConsentOnAccept', e => {
  console.log(e);
  if (e.detail.wpl_viewed_cookie == 'yes') {
    if (e.detail.wpl_user_preference.analytics == 'yes') {
      enableGoogleAnalytics();
      
    }
  }
}, false);


function enableGoogleAnalytics() { 
  console.log('analytics');

  var s = document.createElement('script');
  s.type = "text/javascript"
  s.async = "true";
  s.src = "https://www.googletagmanager.com/gtag/js?id=AW-397375557"; // Add tracking code here
  var x = document.getElementsByTagName('script')[0];
  x.parentNode.insertBefore(s, x);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'AW-397375557');
  gtag('config', 'UA-19597936-10');

  if (document.getElementsByClassName('hf-form-266')) {
    var elements = document.getElementsByClassName("hf-form-266");

    var myTrackingFunction = function() {
      console.log('conversion');
      gtag('send', 'event', 'Formular', 'Absenden','Kontaktformular', 30);
      
      var callback = function () {
        if (typeof(url) != 'undefined') {
          window.location = url;
        }
      };
      gtag('event', 'conversion', {
          'send_to': 'AW-397375557/IOFgCOeCr_4BEMXwvb0B',
          'event_callback': callback
      });
      return false;
    };

    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('submit', myTrackingFunction, false);
    }
  }
  
}


// checked analytics checkbox
document.getElementById("gdpr_messagebar_body_button_analytics").checked = true;