/* eslint-disable prettier/prettier */
/* eslint-disable no-undef */
//necessary because of "google"

document.addEventListener('DOMContentLoaded', () => {

  document.querySelectorAll('.toggleMarker').forEach(checkbox => {
    checkbox.addEventListener('click', () => {
      infoWindow.close();
      toggleGroup(checkbox.dataset.group);
    });
  });


});

var markerGroups = {
  'nordsee': [],
  'ostsee': [],
}

var map;
var infoWindow;
var bounds;
var customIcon = '../app/themes/main/dist/img/green-marker.svg';
var mapOptions = {
  zoom: 8,
  center: { lat: 54.323292, lng: 10.122765 },
  disableDefaultUI: true,
  styles: [
    {
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#f5f5f5",
        },
      ],
    },
    {
      "elementType": "labels",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161",
        },
      ],
    },
    {
      "elementType": "labels.text.stroke",
      "stylers": [
        {
          "color": "#f5f5f5",
        },
      ],
    },
    {
      "featureType": "administrative",
      "elementType": "geometry",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "administrative.land_parcel",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "administrative.land_parcel",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#bdbdbd",
        },
      ],
    },
    {
      "featureType": "administrative.neighborhood",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "poi",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "poi",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee",
        },
      ],
    },
    {
      "featureType": "poi",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575",
        },
      ],
    },
    {
      "featureType": "poi.park",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5",
        },
      ],
    },
    {
      "featureType": "poi.park",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e",
        },
      ],
    },
    {
      "featureType": "road",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "road",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#ffffff",
        },
      ],
    },
    {
      "featureType": "road",
      "elementType": "labels.icon",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "road.arterial",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#757575",
        },
      ],
    },
    {
      "featureType": "road.highway",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#dadada",
        },
      ],
    },
    {
      "featureType": "road.highway",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#616161",
        },
      ],
    },
    {
      "featureType": "road.local",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e",
        },
      ],
    },
    {
      "featureType": "transit",
      "stylers": [
        {
          "visibility": "off",
        },
      ],
    },
    {
      "featureType": "transit.line",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#e5e5e5",
        },
      ],
    },
    {
      "featureType": "transit.station",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#eeeeee",
        },
      ],
    },
    {
      "featureType": "water",
      "elementType": "geometry",
      "stylers": [
        {
          "color": "#c9c9c9",
        }, {
          "lightness" : 60,
        },
      ],
    },
    {
      "featureType": "water",
      "elementType": "labels.text.fill",
      "stylers": [
        {
          "color": "#9e9e9e",
        },
      ],
    },
  ],
}

function initMap() {
  map = new google.maps.Map(document.getElementById("map"), mapOptions);

  infoWindow = new google.maps.InfoWindow();
  bounds = new google.maps.LatLngBounds();

  var markers = document.querySelectorAll('#mapMarker .marker');
  markers.forEach(marker => {

    var point = new google.maps.LatLng(marker.dataset.lat, marker.dataset.lng);
    bounds.extend(point);

    var infoWindowContent = `<strong>${marker.dataset.title}</strong><br>${marker.innerHTML}`;

    var titleLength = 25 + marker.dataset.title.length*3
    var image = {
      url: customIcon,
      scaledSize: new google.maps.Size(20, 20),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(12.5,12.5),
      labelOrigin:  new google.maps.Point(titleLength,11),
    };
    var mapMarker = new google.maps.Marker({
      map: map,
      label : {
        text: marker.dataset.title,
        color: '#222222',
        fontSize: '12px',
        className: 'mapmarkerlabel',
      },
      position: point,
      icon: image,
    });

    bindInfoWindow(mapMarker, map, infoWindow, infoWindowContent);
  
  });
  

  var screenWidth = window.screen.width;
  if (screenWidth < 600 )
  {
    var pt = new google.maps.LatLng(54.469572083685314, 9.061938936249964); // husum
    map.setCenter(pt);
    map.setZoom(7.5);
  } else {
    map.fitBounds(bounds);
  }

  // Try HTML5 geolocation.
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function (position) {
      user_location = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
      map.setCenter(user_location);
    });
  } else {
    // Browser doesn't support Geolocation
    // could handle error
  }
}



function toggleGroup(type) {
  const keys = Object.keys(markerGroups);
  for (const key of keys) {
    // hide all markers
    markerGroups[key].forEach(m => {
      m.setVisible(false);
    });
  }
  // show selected marker type
  markerGroups[type].forEach(m => {
    m.setVisible(true);
  });

}


function bindInfoWindow(marker, map, infoWindow, html) {
  google.maps.event.addListener(marker, 'click', () => {
    // map.panTo(marker.getPosition());
    // map.setZoom(13);
    infoWindow.setContent(html);
    infoWindow.open(map, marker);
  });
  // google.maps.event.addListener(infoWindow, 'closeclick', () => {
  //   map.setZoom(8);
  // });
}


/**
 * Webpack Fix - add global scope to function
 */
global.initMap = initMap;
